<mat-drawer-container hasBackdrop>
    <mat-drawer autoFocus="false" #drawer mode="over" position="end" (closed)="close()">
      <app-right-sidebar-container
        [title]="(isEditing ? 'Update' : 'Create') + ' Organization'"
        [footerSubmitCustomLabel]="isEditing ? 'Update' : 'Create'"
        (cancel)="close()"
        (submit)="onSubmit()"
        [disableSubmit]="form.invalid || loadingValidator"
      >
        <form [formGroup]="form" class="provider-content">
          <div class="custom-form-field">
            <label for="lenderIds">Organization Name<span class="required">*</span></label>
            <mat-form-field appearance="outline" class="custom-field">
              <input formControlName="name" matInput name="name" />
              <mat-error *ngIf="form.controls['name'].errors?.['required']">
                Please insert Organization name
              </mat-error>
              <mat-error *ngIf="form.controls['name'].errors?.['entityExists']">
                Organization already exist with this name
              </mat-error>
            </mat-form-field>
          </div>
          <div class="custom-form-field" *ngIf="productList.length > 0; else emptyProducts">
            <label for="productIds">Product(s)<span class="required">*</span></label>
            <app-mat-multi-select
              [requiredMessage]="'Please select Product'"
              [options]="productList"
              [control]="form.controls['productIds']"
            />
          </div>
          <ng-template #emptyProducts>
            <div class="custom-form-field">
              <label for="productEmpty">Product(s)<span class="required">*</span></label>
              <mat-form-field appearance="outline" class="custom-field">
                <input
                  [disabled]="true"
                  matInput
                  name="productEmpty"
                />
              </mat-form-field>
            </div>
          </ng-template>
          <div class="custom-form-field">
            <label for="notes">Organization Description<span class="required">*</span></label>
            <mat-form-field
              appearance="outline"
              class="custom-field textarea no-hint"
            >
              <textarea
                formControlName="description"
                matInput
                name="description"
                rows="3"
              ></textarea>
            </mat-form-field>
            <mat-error
              *ngIf="form.controls['description'].touched && form.controls['description'].errors?.['required']"
            >
              Please enter Organization description
            </mat-error>
          </div>
        </form>
      </app-right-sidebar-container>
    </mat-drawer>
    <mat-drawer-content>
      <div class="pcs-container">
        <div class="pcs-header">
          <h3 class="pcs-title">Lender Organizations</h3>
          <div class="card-actions" *ngIf="isAdmin()">
            <button
              (click)="openRightSidebar()"
              mat-flat-button
              color="primary"
              type="button"
            ><mat-icon>add</mat-icon> Create</button>
          </div>
        </div>
        <app-search-sort
          [searchControl]="searchControl"
          [sortForm]="sortForm"
          [sortOptions]="sortOptions"
          (apply)="setSortField()"
          (close)="resetSortField()"
        ></app-search-sort>
        <ng-container *ngIf="isLoading; else templateGrid">
          <div class="loader-container">
            <app-loader />
          </div>
        </ng-container>
        <ng-template #templateGrid>
          <ng-container *ngIf="dataToShow.length > 0;else templateEmpty">
            <app-card-grid-item
              *ngFor="let data of dataToShow"
              [id]="data.id"
              [title]="data.title || ''"
              [infoContent]="data.list ? data.list : []"
              [statusContent]="data.status ? data.status : []"
              [actionList]="data.actions"
              (actionClicked)="onActionClicked($event)"

            ></app-card-grid-item>
            <mat-paginator
              #paginator
              [selectConfig]="{panelClass: 'mat-paginator-select-options'}"
              [length]="this.totalCount"
              [pageIndex]="this.currentPage"
              [pageSize]="this.pageSize"
              [pageSizeOptions]="pageSettings.pageSizeOptions"
              (page)="handlePageEvent($event)"
              aria-label="Select page">
            </mat-paginator>
          </ng-container>
          <ng-template #templateEmpty>
            <app-empty-data></app-empty-data>
          </ng-template>
        </ng-template>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
