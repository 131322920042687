export const ADMIN_NAV_ROUTES: INavRoute[] = [
  {
    id: 1,
    label: 'Dashboard',
    path: '/admin/dashboard',
    icon: {
      name: 'dashboard',
    },
  },
  {
    id: 2,
    label: 'Providers',
    icon: {
      name: 'supervisor_account',
    },
    children: [
      {
        id: 21,
        label: 'List',
        path: '/admin/provider-organization-list',
      },
      {
        id: 22,
        label: 'Form Submissions',
        path: '/admin/form-submission/list',
      },
      {
        id: 23,
        label: 'Form Consolidation Requests',
        path: '/admin/form-consolidation/list',
      },
      {
        id: 24,
        label: 'Marketing Materials',
        path: '/admin/marketing-material/list',
      },
    ],
  },
  {
    id: 3,
    label: 'Lenders',
    icon: {
      name: 'handshake',
    },
    children: [
      {
        id: 31,
        label: 'List',
        path: '/admin/lender-organization-list',
      },
      {
        id: 32,
        label: 'MBR Review Requests',
        path: '/admin/mbr-request/list',
      },
      {
        id: 33,
        label: 'Product MBRs',
        path: '/admin/lenders-mbr-list',
      },
      {
        id: 34,
        label: 'External Approvals',
        path: '/admin/external-approval/list',
      },
    ],
  },
  {
    id: 4,
    label: 'Products',
    icon: {
      name: 'manage_accounts',
    },
    children: [
      {
        id: 31,
        label: 'List',
        path: '/admin/products-list',
      }
    ],
  },
  {
    id: 5,
    label: 'Employees',
    icon: {
      name: 'perm_contact_calendar',
    },
    children: [
      {
        id: 51,
        label: 'List',
        path: '/admin/user-list',
      }
    ],
  },
];

export const SYSTEM_USER_NAV_ROUTES: INavRoute[] = [
  {
    id: 1,
    label: 'Dashboard',
    path: '/admin/dashboard',
    icon: {
      name: 'dashboard',
    },
  },
  {
    id: 2,
    label: 'Providers',
    icon: {
      name: 'supervisor_account',
    },
    children: [
      {
        id: 21,
        label: 'List',
        path: '/admin/provider-organization-list',
        permission: ['Providers'],
      },
      {
        id: 22,
        label: 'Form Submissions',
        path: '/admin/form-submission/list',
        permission: ['FormSubmission'],

      },
      {
        id: 23,
        label: 'Form Consolidation Requests',
        path: '/admin/form-consolidation/list',
        permission: ['FormConsolidation'],

      },
      {
        id: 24,
        label: 'Marketing Materials',
        path: '/admin/marketing-material/list',
        permission: ['MarketingMaterial'],
      },
    ],
  },
  {
    id: 3,
    label: 'Lenders',
    icon: {
      name: 'handshake',
    },
    children: [
      {
        id: 31,
        label: 'List',
        path: '/admin/lender-organization-list',
        permission: ['Lenders']
      },
      {
        id: 32,
        label: 'MBR Review Requests',
        path: '/admin/mbr-request/list',
        permission: ['MBRReview']

      },
      {
        id: 33,
        label: 'Product MBRs',
        path: '/admin/lenders-mbr-list',
        permission: ['MBRReview']

      },
      {
        id: 34,
        label: 'External Approvals',
        path: '/admin/external-approval/list',
        permission: ['ExternalApproval']
      },
    ],
  },
  {
    id: 4,
    label: 'Products',
    icon: {
      name: 'manage_accounts',
    },
    children: [
      {
        id: 31,
        label: 'List',
        path: '/admin/products-list',
      }
    ],
  },
];

export const PROVIDERS_NAV_ROUTES: INavRoute[] = [
  {
    id: 1,
    label: 'Dashboard',
    path: '/provider/dashboard',
    icon: {
      name: 'dashboard',
    },
  },
  {
    id: 2,
    label: 'MBR List',
    path: '/provider/product-mbr-list',
    icon: {
      name: 'list',
      type: 'solid'
    },
  },
  {
    id: 3,
    label: 'Form Submissions',
    path: '/provider/form-submission/list',
    icon: {
      name: 'feed',
    },
  },
  {
    id: 4,
    label: 'Form Consolidation Requests',
    path: '/provider/form-consolidation/list',
    icon: {
      name: 'workspaces',
    },
  },
  {
    id: 5,
    label: 'Marketing Materials',
    path: '/provider/marketing-material/list',
    icon: {
      name: 'file_present',
    },
  },
  {
    id: 6,
    label: 'Lenders',
    path: '/provider/lenders',
    icon: {
      name: 'handshake',
    },
  },
  {
    id: 7,
    label: 'Organization & Documentation',
    path: '/provider/organization',
    icon: {
      name: 'apartment',
    },
  },
];

export const LENDERS_NAV_ROUTES: INavRoute[] = [
  {
    id: 1,
    label: 'Dashboard',
    path: '/lender/dashboard',
    icon: {
      name: 'dashboard',
    },
  },
  {
    id: 2,
    label: 'Product MBRs',
    path: '/lender/mbr-list',
    icon: {
      name: 'work_outline',
    },
  },
  {
    id: 3,
    label: 'Product MBR Requests',
    path: '/lender/mbr-request/list',
    icon: {
      name: 'cases',
    },
  },
  {
    id: 5,
    label: 'Form Submissions',
    path: '/lender/form-submission/list',
    icon: {
      name: 'feed',
    },
  },
  {
    id: 7,
    label: 'Marketing Materials',
    path: '/lender/marketing-material/list',
    icon: {
      name: 'file_present',
    },
  },
  {
    id: 8,
    label: 'External Approvals',
    path: '/lender/external-approval/list',
    icon: {
      name: 'approval',
    },
  },
]

export interface INavRoute {
  id: number;
  label: string;
  icon?: {
    name: string,
    type?: ''|'solid'
  };
  path?: string;
  children?: INavRoute[];
  permission?: string[]
}

export type ROLE_ROUTE = 'Admin' | 'SystemUser' | 'Lender' | 'Provider';

