<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title">Notifications</h3>
    <div class="card-actions">
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="countSelected == 0"
        [matBadge]="countSelected"
        [matBadgeSize]="'small'"
        [matBadgeColor]="'warn'"
        (click)="onMarkAsRead()"
      >
        Mark as read
      </button>
    </div>
  </div>
  <app-search-sort
    [searchControl]="searchControl"
    [sortForm]="sortForm"
    [sortOptions]="sortOptions"
    (apply)="setSortField()"
    (close)="resetSortField()"
  ></app-search-sort>
  <ng-container *ngIf="isLoading; else templateGrid">
    <div class="loader-container">
      <app-loader />
    </div>
  </ng-container>
  <ng-template #templateGrid>
    <ng-container *ngIf="dataToShow.length > 0;else templateEmpty">
      <app-card-grid-item
        *ngFor="let data of dataToShow"
        [id]="data.id"
        [title]="data.title || ''"
        [infoContent]="data.list ? data.list : []"
        [statusContent]="data.status ? data.status : []"
        [actionList]="data.actions"
        (actionClicked)="onActionClicked($event)"
      ></app-card-grid-item>
      <mat-paginator
        #paginator
        [selectConfig]="{ panelClass: 'mat-paginator-select-options' }"
        [length]="this.totalCount"
        [pageIndex]="this.currentPage"
        [pageSize]="this.pageSize"
        [pageSizeOptions]="pageSettings.pageSizeOptions"
        (page)="handlePageEvent($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </ng-container>
    <ng-template #templateEmpty>
      <app-empty-data></app-empty-data>
    </ng-template>
  </ng-template>
</div>
