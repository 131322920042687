import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { compareObjects } from '../../utils';

@Component({
  selector: 'app-search-sort',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatMenuModule,
  ],
  templateUrl: './search-sort.component.html',
  styleUrls: ['./search-sort.component.scss'],
})
export class SearchSortComponent {
  @Input() searchControl: FormControl;
  @Input() sortForm: FormGroup;
  @Input() sortOptions: any[] = [];
  @Output() apply = new EventEmitter();
  @Output() close = new EventEmitter()


  //? This is temporarily till all the backend pagination is done
  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.sortValue === o2.sortValue : o1 === o2;
  }

  onMenuClose() {
    this.close.emit();
  }

  onApply() {
    this.apply.emit();
  }

}
