<div class="pro-card">
  <div class="pro-card-info" [ngClass]="{fr3: infoContent.length < 3}">
    <div class="row-title">
      <span class="title">{{ title }}</span>
      <ng-content select="[title-tag]"></ng-content>
    </div>
    <span class="info-content" *ngFor="let info of infoContent"
      ><strong>{{ info.label }}:</strong>
      <ng-container *ngIf="isArray(info.value); else isNotArrayTemplate">
        <div class="array-row">
          <span *ngFor="let item of getInfoValue(info) | slice : 0 : 3">
            {{ item.name }}
          </span>
          <span *ngIf="info.value.length > 3"
            >+{{ info.value.length - 3 }}</span
          >
        </div>
      </ng-container>
      <ng-template #isNotArrayTemplate>
        <span class="text-value">{{ info.value }}</span>
      </ng-template>
    </span>
  </div>
  <div class="vertical-separator"></div>
  <div class="pro-card-status">
    <div class="status-list">
      <app-status-badge
        class="status-badge"
        *ngFor="let status of statusContent | slice : 0 : 5"
        [text]="status.label"
        [status]="status.value"
        [tooltip]="status.tooltip"
        [size]="'large'"
      />
      <app-status-badge
        class="status-badge"
        *ngIf="statusContent.length > 5"
        [text]="getExtraCount()"
        [status]="'primary'"
        [size]="'large'"
      />
      <app-document-badge
        *ngFor="let document of documentContent"
        [name]="document.label"
        [serverFileName]="document.value"
      />
    </div>
    <div class="actions-container">
      <button
        *ngFor="let action of actionList"
        class="primary medium"
        mat-stroked-button
        [class]="action.class"
        [color]="action.color"
        (click)="onActionClicked(action.id)"
      >
        <mat-icon class="icon-btn" fontSet="material-icons-outlined">{{
          action.icon
        }}</mat-icon>
        {{ action.title }}
      </button>
    </div>
  </div>
</div>
