<div class="row-container">
  <div class="pro-card" *ngFor="let item of data">
    <h5 class="title">{{ item.title }}</h5>
    <p class="descrition" *ngIf="item.description">
      {{ item.description }}
    </p>
    <div
      *ngIf="item.customDescription"
      [ngClass]="item?.customDescription?.class"
    >
      <span class="material-icons icon">
        {{ item?.customDescription?.icon }}
      </span>
      <span class="custom-text">
        {{ item?.customDescription?.description }}
      </span>
    </div>
    <ng-content></ng-content>
  </div>
</div>
