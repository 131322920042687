import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';

@Injectable({
  providedIn: 'root'
})

export class ProvidersService extends BaseService {

  private apiUrl = `providers`;
  private httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(http, `providers`);
  }

  getByOrganizationId(id: number, filters: any): Observable<APIResponseModel<any>> {
      return this.http.get<APIResponseModel<any>>(
        `${this.baseUrl}/organization/${id}`,
        filters ? { params: {...filters } } : { params: {} })
      .pipe(
          catchError((err: HttpErrorResponse) => {
            return this.httpErrorHandler(err, {});
          })
      );
  }


  changeUserAvailability(id: string, enabled: boolean): Observable<APIResponseModel<any>> {
    return this.http
      .post<APIResponseModel<any>>(`${this.baseUrl}/change-availability`, { id, enabled: enabled })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.httpErrorHandler(err, {});
        })
      );
  }

}
