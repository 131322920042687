import {AbstractControl, AsyncValidatorFn} from '@angular/forms';
import { BaseService } from '../services/base-service';
import { catchError, map, of } from 'rxjs';

export function entityExistsValidator(
  service: BaseService,
  currentId?: string,
  setLoading?: (isLoading: boolean) => void
): AsyncValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      setLoading?.(false);
      return of(null);
    }

    if (currentId && control.value === currentId) {
      setLoading?.(false);
      return of(null);
    }

    setLoading?.(true);
    return service.entityExists(control.value).pipe(
      map(res => {
        setLoading?.(false);
        return res.data ? { entityExists: true } : null;
      }),
      catchError(() => {
        setLoading?.(false);
        return of(null);
      })
    );
  };
}
