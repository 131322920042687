
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LenderRoutingModule } from './lender-routing.module';

// Lender componenet
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    LenderRoutingModule,
    NgxDropzoneModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
  ],
  exports: [
  ]
})
export class LenderModule { }
