<div class="custom-form-field">
  <mat-form-field
    class="search-field custom-field md no-hint"
    appearance="outline"
  >
    <input
      type="text"
      placeholder="Search"
      aria-label="Search"
      matInput
      [formControl]="searchControl"
    />
    <mat-icon color="primary" matSuffix>search</mat-icon>
  </mat-form-field>

  <button
    class="primary sort-btn"
    mat-stroked-button
    #sortTrigger="matMenuTrigger"
    [matMenuTriggerFor]="sortMenu"
  >
    <mat-icon class="icon-btn" fontSet="material-icons-outlined">
      sort
    </mat-icon>
  </button>
  <mat-menu
    #sortMenu="matMenu"
    [class]="'sort-menu'"
    [overlapTrigger]="false"
    (close)="onMenuClose()"
  >
    <div class="sort-menu-container">
      <form [formGroup]="sortForm">
        <h6>Sort By</h6>
        <div class="custom-form-field">
          <mat-form-field
            (click)="$event.stopPropagation()"
            appearance="outline"
            class="custom-field sm no-hint"
          >
            <mat-select formControlName="sortField" name="sortField" [compareWith]="compareObjects">
              <mat-option *ngFor="let option of sortOptions" [value]="{ sortValue: option.sortValue }">
                {{ option.label }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
          </mat-form-field>
        </div>
        <div class="custom-form-field">
          <mat-radio-group
            class="column-options"
            color="primary"
            (click)="$event.stopPropagation()"
            formControlName="isAscending" name="isAscending"
          >
            <mat-radio-button [value]="true" class="form-item">
              <div class="form-text">
                <span>Ascendant</span>
              </div>
            </mat-radio-button>
            <mat-radio-button [value]="false" class="form-item">
              <div class="form-text">
                <span>Descendant</span>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="apply-row">
          <button
            mat-flat-button
            color="primary"
            type="button"
            class="apply-button sm"
            (click)="onApply()"
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  </mat-menu>
</div>
