<mat-form-field appearance="outline" class="custom-field w-100" [ngClass]="{sm: size == 'sm'}">
  <mat-select
    (openedChange)="openedChange($event)"
    [formControl]="getFormControl()"
    [placeholder]="innerLabel"
    multiple
  >
    <mat-select-trigger class="select-trigger">
      {{ getValuesSample() }}
      <span
        *ngIf="selectFormControl.value?.length > 3"
        class="additional-selection"
      >
        +{{ selectFormControl.value.length - 3 }}
      </span>
    </mat-select-trigger>
    <div class="select-container">
      <mat-optgroup class="full-width">
        <mat-form-field appearance="outline" class="custom-field sm no-hint">
          <input
            #search
            autocomplete="off"
            placeholder="Search"
            aria-label="Search"
            matInput
            [formControl]="searchTextboxControl"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </mat-optgroup>
      <mat-optgroup *ngIf="filteredOptions?.length == 0 && !loading">
        <div>No results found!</div>
      </mat-optgroup>
      <div class="loader-container" *ngIf="loading">
        <app-loader></app-loader>
      </div>
      <div class="options-container" #optionsContainer>
        <app-mat-select-all  *ngIf="!loading" [model]="getFormControl()" [values]="filteredOptions || []" />
        <mat-option
          class="small"
          *ngFor="let option of filteredOptions"
          [value]="option"
        >
          {{ option.name }}
        </mat-option>
      </div>
    </div>
  </mat-select>
  <mat-icon matSuffix>expand_more</mat-icon>
  <mat-error
    *ngIf="getFormControl().errors?.['required']"
  >
    {{ requiredMessage }}
  </mat-error>
</mat-form-field>
