
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProviderRoutingModule } from './provider-routing.module';

import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ProviderRoutingModule,
    NgxDropzoneModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
  ]
})
export class ProviderModule { }
