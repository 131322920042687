<mat-drawer-container hasBackdrop>
    <mat-drawer autoFocus="false" #drawer mode="over" position="end" (closed)="close()">
    </mat-drawer>
    <mat-drawer-content>
      <div class="pcs-container">
        <div class="pcs-header">
          <h3 class="pcs-title">Product MBRs</h3>
          <div class="card-actions">
            <a
              *ngIf="canCreate()"
              (click)="onRequestMBR()"
              mat-flat-button
              color="primary"
              type="button"
            ><mat-icon>add</mat-icon> Request MBR</a>
          </div>
        </div>
        <app-search-sort
          [searchControl]="searchControl"
          [sortForm]="sortForm"
          [sortOptions]="sortOptions"
          (apply)="setSortField()"
          (close)="resetSortField()"
        ></app-search-sort>
        <ng-container *ngIf="isLoading; else templateGrid">
          <div class="loader-container">
            <app-loader />
          </div>
        </ng-container>
        <ng-template #templateGrid>
          <ng-container *ngIf="dataToShow.length > 0;else templateEmpty">
            <app-card-grid-item
              *ngFor="let data of dataToShow"
              [id]="data.id"
              [title]="data.title || ''"
              [infoContent]="data.list ? data.list : []"
              [statusContent]="data.status ? data.status : []"
              [documentContent]="data.documents"
              [actionList]="formActions"
              (actionClicked)="onActionClicked($event)"

            ></app-card-grid-item>
            <mat-paginator
              #paginator
              [selectConfig]="{panelClass: 'mat-paginator-select-options'}"
              [pageSizeOptions]="pageSettings.pageSizeOptions"
              [length]="this.totalCount"
              [pageIndex]="this.currentPage"
              [pageSize]="this.pageSize"
              (page)="handlePageEvent($event)"
              aria-label="Select page">
            </mat-paginator>
          </ng-container>
          <ng-template #templateEmpty>
            <app-empty-data></app-empty-data>
          </ng-template>
        </ng-template>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
