
<div *ngIf="!isLoadingLicenses && providerLicenses.length == 0" class="pro-card license-card">
  <p class="license-card-empty-text">No documents yet</p>
</div>
<ng-container *ngIf="isLoadingLicenses; else templateGrid">
  <div class="loader-container">
    <app-loader />
  </div>
</ng-container>
<ng-template #templateGrid>
  <div class="pro-card license-card" *ngFor="let license of providerLicenses">
    <div *ngIf="license.products.length > 0" class="license-row">
      <p class="license-card-title">{{license.products[0].name}}</p>
      <div class="license-expiration-date-container">
        <mat-icon>schedule</mat-icon>
        <span class="license-expiration-date">Expires: {{license.expirationDate | date: 'MM/dd/yy' }}</span>
      </div>
    </div>
    <div *ngIf="license.applicableStates.length > 0" class="license-row">
      <div class="license-state-container">
        <p class="license-states-title">Applicable States:</p>
        <div class="license-state-detail-container">
          <div class="license-state-detail" *ngFor="let state of getApplicableStatesToShow(license)">{{state.name}}</div>
          <div class="license-state-detail" *ngIf="exceedsMaximumStatesToShow(license)">+{{remainingStatesToShow(license)}}</div>
        </div>
    </div>
    </div>
    <div class="license-row">
      <div class="license-copies-container">
        <div *ngFor="let document of license.documentFiles" class="license-copies">
          <mat-icon fontSet="material-icons-outlined">insert_drive_file</mat-icon>
          <span (click)="downloadDocument(document)" class="license-copy">{{document.name}}</span>
        </div>
      </div>
      <div class="license-actions">
        <button mat-stroked-button *ngFor="let action of licenseActions" (click)="action.click(license)" [class]="action.cssClass">
          <mat-icon class="icon-btn card-action-icon" fontSet="material-icons-outlined">{{action.icon}}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
