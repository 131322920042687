import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PcsDashboardComponent } from '../shared/components/pcs-dashboard/pcs-dashboard.component';
import { AuthenticationGuard } from 'src/app/authentication.guard';
import { NotificationsListComponent } from '../shared/views/notifications-list/notifications-list.component';
import { HomeComponent } from '../shared/components/home/home.component';
import { ProvidersListComponent } from '../shared/views/providers-list/providers-list.component';
import { LenderOrganizationListComponent } from '../shared/views/lender-organization-list/lender-organization-list.component';
import { ProductMbrListComponent } from '../shared/views/product-mbr-list/product-mbr-list.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      { path: 'dashboard', component: PcsDashboardComponent },
      { path: 'product-mbr-list', component: ProductMbrListComponent },
      { path: 'notifications-list', component: NotificationsListComponent },
      { path: 'lenders', component: LenderOrganizationListComponent },
      { path: 'organization', component: ProvidersListComponent },
      {
        path: 'form-submission',
        loadChildren: () =>
          import(
            '../shared/views/form-submission/form-submission.route'
          ).then((mod) => mod.FORM_SUBMISSION_ROUTES),
        canActivate: [AuthenticationGuard],

      },
      {
        path: 'search',
        loadChildren: () =>
          import(
            '../shared/views/general-search/general-search.route'
          ).then((mod) => mod.GENERAL_SEARCH_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'form-consolidation',
        loadChildren: () =>
          import(
            '../shared/views/form-consolidation/form-consolidation.route'
          ).then((mod) => mod.FORM_CONSOLIDATION_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'marketing-material',
        loadChildren: () =>
          import(
            '../shared/views/marketing-material/marketing-material.route'
          ).then((mod) => mod.MARKETING_MATERIAL_ROUTES),
        canActivate: [AuthenticationGuard],
      },
      { path: '', redirectTo: '/provider/dashboard', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderRoutingModule { }
