import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from 'src/app/services/notifications.service';
import { IHeaderAction } from '../../components/header-actions/header-actions.interface';
import { DatePipe } from '@angular/common';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ICardListInfoData } from '../../components/cards/card-item.interface';
import { PageEvent } from '@angular/material/paginator';
import { sortData } from '../../utils';
@Component({
  selector: 'app-mbr-request-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  isLoading: boolean = false;
  submissionService: any;
  countSelected = 0;
  selectedRowIds: number[] = [];
  public dataNotifications: any[] = [];

  formActions: IHeaderAction[] = [
    {
      id: 'detail',
      title: 'View Detail',
      type: 'stroke',
      icon: 'visibility',
      class: 'primary',
    },
    {
      id: 'select',
      title: '',
      type: 'stroke',
      icon: 'check_box',
      class: 'primary no-text no-border',
    },
    {
      id: 'unselect',
      title: '',
      type: 'flat',
      icon: 'check_box_outline_blank',
      class: 'primary no-text no-border',
    },
  ];

  pageSettings: {
    defaultPageSize: number;
    pageSizeOptions: number[];
  } = {
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 15],
  };
  datePipe = new DatePipe('en-US');
  currentPage: number = 0;
  pageSize: number = this.pageSettings.defaultPageSize;
  subscriptions: Subscription[] = [];
  searchControl: FormControl = new FormControl('');
  dataToShow: any[];

  currentSortField = {
    sortValue: '',
  };
  currentIsAscending = false;

  totalCount: number = 0;
  searchKey: string = '';

  sortForm: FormGroup = new FormGroup({
    sortField: new FormControl({
      sortValue: this.currentSortField.sortValue,
    }),
    isAscending: new FormControl(this.currentIsAscending),
  });

  sortOptions = [
    { label: 'Entity', sortValue: 'entity' },
    { label: 'Date', sortValue: 'date' },
  ];

  constructor(private service: NotificationService, private router: Router) {}

  ngOnInit() {
    const searchSubscription = this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => {
          this.currentPage = 0;
          this.reload();
        }),
      )
      .subscribe();

    this.subscriptions.push(searchSubscription);

    this.reload();
  }

  private buildInfoData(action: any): ICardListInfoData {
    let list = [
      {
        label: 'Description',
        value: action.description,
      },
      {
        label: 'Date',
        value: this.datePipe.transform(action.dateTime, 'MM-dd-yyyy'),
      },
    ];

    return {
      id: action.id,
      title: action.title,
      list,
      actions: this.getActionsFor(action.id),
    } as ICardListInfoData;
  }

  onActionClicked(event: any) {
    const data = this.dataNotifications.find((d) => d.id == event.id);

    if (!data) return;

    switch (event.actionId) {
      case 'detail':
        this.onView(data);
        break;

      case 'select':
        this.onSelection(data, false);
        break;

      case 'unselect':
        this.onSelection(data, true);
        break;

      default:
        break;
    }
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reload();
  }

  private reload() {
    this.isLoading = true;
    this.searchKey = this.searchControl.value?.toLowerCase() || '';

    const serviceSubscription = this.service
        .get({
          sortColumn: this.currentSortField.sortValue,
          sortOrder: this.currentIsAscending ? 'asc' : 'desc',
          page: this.currentPage + 1,
          pageSize: this.pageSize,
          searchKey: this.searchKey
        }).pipe(
        switchMap((response: any) => {
          if (!response.isError) {
            return of(response.data);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((data) => {
        this.dataNotifications = data.items;
        this.totalCount = data.totalCount;
        this.dataToShow = this.transformCardData(this.dataNotifications)
        this.isLoading = false;
      });

      this.subscriptions.push(serviceSubscription);
  }

  private transformCardData(data: any) {
    const infoFormSubmission: ICardListInfoData[] = data.map((action: any) => {
      let infoData = this.buildInfoData(action);

      infoData.title = action.title;
      return infoData;
    });

    return infoFormSubmission;
  }

  setSortField() {
    const { sortField, isAscending } = this.sortForm.value;
    this.currentIsAscending = isAscending;
    this.currentSortField = sortField;

    this.reload();
  }

  resetSortField() {
    this.sortForm.setValue({
      sortField: this.currentSortField,
      isAscending: this.currentIsAscending,
    });
  }

  onView(data: any) {
    this.service.markAsRead([data.id]).subscribe((_) => {
      this.service.notifyBellToUpdate();
      let url = this.service.navigate(data.entity, data.referenceId);
      this.router.navigate([url]);
    });
  }

  onSelection(event: any, selected: boolean) {
    if(selected) {
      this.selectedRowIds.push(event.id);
    }
    else {
      this.selectedRowIds = this.selectedRowIds.filter(x => x != event.id);
    }
    this.countSelected = this.selectedRowIds.length;
    this.reload();
  }

  onMarkAsRead() {
    this.service.markAsRead(this.selectedRowIds).subscribe((_) => {
      this.reload();
      this.countSelected = 0;
      this.selectedRowIds = [];
      this.service.notifyBellToUpdate();
    });
  }

  public getActionsFor(id: number): IHeaderAction[] {
    if (this.selectedRowIds?.includes(id)) {
      return this.formActions.filter((a) => a.id !== 'unselect');
    } else {
      return this.formActions.filter((a) => a.id !== 'select');
    }
  }


  ngOnDestroy(): void {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }
}
